.footer {
    background-color: #2c3e50;
    color: white;
    padding: 20px 0;
    text-align: center;
    margin-top: 40px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer p {
    margin: 0;
  }
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icons a img {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
    
    .social-icons {
      margin-top: 15px;
    }
  }