.main-content {
  padding: 20px;
}

/* Flexbox layout for Address and Map side-by-side */
.contact-info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Address Section Styling */
.address-section {
  width: 45%;
  padding-right: 20px;
}

.map-section {
  width: 50%;
}

.map-section iframe {
  width: 100%; /* Make the map responsive */
  height: 100%;
  border: none;
}

/* Contact Form Styling */
form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form input, form textarea, form select {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

form button {
  width: auto;
  padding: 12px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #2980b9;
}
