body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
  }
  
  h1, h2, h3 {
    color: #2c3e50;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 15px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 1rem;
  }
  .main-content {
    padding: 20px;
  }
  
  .section {
    padding: 40px 20px;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  
  .section-content {
    margin-bottom: 40px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the form elements horizontally */
    max-width: 600px; /* Set a maximum width for the form */
    margin: 0 auto; /* Center the form on the page */
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  form input, form textarea, form select {
    width: 100%; /* Make sure input elements don't exceed the width of the form */
    max-width: 500px; /* Set a maximum width for inputs */
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding in the element's width */
  }
  
  form button {
    width: auto;
    padding: 12px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #2980b9;
  }
  
  .button {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .button:hover {
    background-color: #2980b9;
  }
.countdown-clock {
    position: fixed;
    top: 20%;
    right: 20px;
    background-color: #3498db;
    color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .countdown-clock h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .countdown-clock p {
    font-size: 1.2rem;
  }
  .home_content{
    padding: 20px;
    max-width : 900px;
  }
  .news-box {
    background-color: #f9f9f9;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin-left: 20px;
    text-align: left;
  }
  
  .news-box h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .news-box ul {
    list-style: none;
    padding: 0;
  }
  
  .news-box ul li {
    margin-bottom: 10px;
    font-size: 1rem;
  }