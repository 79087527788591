body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
  }
  
  h1, h2, h3 {
    color: #2c3e50;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 15px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 1rem;
  }
  .main-content {
    padding: 20px;
  }
  
  .section {
    padding: 40px 20px;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  
  .section-content {
    margin-bottom: 40px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center; 
    max-width: 600px; 
    margin: 0 auto; 
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  form input, form textarea, form select {
    width: 100%; 
    max-width: 500px; 
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
  }
  
  form button {
    width: auto;
    padding: 12px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #2980b9;
  }
  
  .button {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .button:hover {
    background-color: #2980b9;
  }
.conference-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .conference-table th, .conference-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .conference-table th {
    background-color: #3498db;
    color: white;
    text-align: left;
  }
  
  .conference-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .conference-table tr:hover {
    background-color: #ddd;
  }
  
  .conference-table td button, .conference-table td a {
    background-color: #2ecc71;
    color: white;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
  }