/* General Styling */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

/* Headers Styling */
h1, h2, h3 {
  color: #2c3e50;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 15px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

/* Paragraph Styling */
p {
  margin-bottom: 20px;
  font-size: 1rem;
}

/* Main Content Area */
.main-content {
  padding: 20px;
  max-width: 1200px; /* Added max width for larger screens */
  margin: 0 auto; /* Center the content */
  background-color: #fff; /* Optional: Set background for main content */
}

/* Sections */
.section {
  padding: 40px 20px;
}

.section-title {
  margin-bottom: 30px;
}

.section-content {
  margin-bottom: 40px;
}

/* Dropdown Styling */
select {
  width: 100%; /* Make the select take full width */
  max-width: 400px; /* Set a maximum width */
  padding: 12px; /* Add padding for better touch area */
  margin-top: 10px; /* Add space above the select */
  margin-bottom: 20px; /* Add space below the select */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Increase font size for better readability */
  background-color: #f9f9f9; /* Light background color */
  transition: border-color 0.3s, background-color 0.3s; /* Transition for hover effect */
}

/* Change border color on focus */
select:focus {
  border-color: #3498db; /* Change border color on focus */
  background-color: #ffffff; /* Change background on focus */
  outline: none; /* Remove outline */
}

/* Change cursor on hover */
select:hover {
  cursor: pointer; /* Pointer cursor on hover */
}

/* Form Styling */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff; /* Changed to white for better contrast */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form input, form textarea, form select {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

form button {
  width: auto;
  padding: 12px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #2980b9;
}

/* Button Styling */
.button {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer; /* Ensured the button looks clickable */
}

.button:hover {
  background-color: #2980b9;
}

/* Export Button */
.export-button {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.export-button:hover {
  background-color: #2980b9;
}

/* Session Details Section */
.session-details {
  margin-top: 20px;
  background-color: #f4f4f9; /* Light background for session details */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for better look */
}

.session-details h2 {
  font-size: 1.5rem;
  margin-bottom: 10px; /* Adjusted margin for better spacing */
}

.session-details p {
  margin-bottom: 10px;
}

/* Custom Class for Available Dates (in Calendar) */
.available-date {
  background-color: green !important;
  color: white !important;
  border-radius: 50%;
}

.available-date:hover {
  background-color: darkgreen !important;
}

/* Adjusting table layouts if needed for your sessions */
table.schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table.schedule-table th, table.schedule-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

table.schedule-table th {
  background-color: #3498db;
  color: white;
}

table.schedule-table tr:hover {
  background-color: #f4f4f9;
}

/* Back Button */
.back-button {
  display: inline-block;
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.back-button:hover {
  background-color: #c0392b;
}
