body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
  }
  
  h1, h2, h3 {
    color: #2c3e50;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 15px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 1rem;
  }
  .main-content {
    padding: 20px;
  }
  
  .section {
    padding: 40px 20px;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  
  .section-content {
    margin-bottom: 40px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center; 
    max-width: 600px; 
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  form input, form textarea, form select {
    width: 100%;
    max-width: 500px; 
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
  }
  
  form button {
    width: auto;
    padding: 12px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #2980b9;
  }
  
  .button {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .button:hover {
    background-color: #2980b9;
  }
.pr-page-layout {
    display: flex;
    justify-content: space-between;
  }
  .pr-content{
    width: 70%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .faq6-box {
    width: 25%;
    background-color: #f4f4f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-right: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 350px;
    margin-left: 20px;
    margin-top: 40px;
  }
  
  .faq6-box h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    margin-top: 0;
  }