nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3498db;
    padding: 10px 20px;
  }
  
  nav ul {
    list-style-type: none;
    justify-content: space-between;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  nav ul li {
    margin: 5px;
  }
  
  nav ul li a {
    font-family: 'Oswald', sans-serif;
    color: white;
    text-decoration: none;
    font-weight: bold;
    white-space: nowrap;
    border: 2px solid transparent;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    padding: 8px 12px;
    font-size: 0.9rem;
    border-radius: 8px;
  }
  
  nav ul li a:hover {
    border-color: white;
    background-color: #2980b9;
  }
  .logout-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .logout-button:hover {
    background-color: #c0392b;
  }