body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
  }
  
  h1, h2, h3 {
    color: #2c3e50;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 15px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 1rem;
  }
  .main-content {
    padding: 20px;
  }
  
  .section {
    padding: 40px 20px;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  
  .section-content {
    margin-bottom: 40px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the form elements horizontally */
    max-width: 600px; /* Set a maximum width for the form */
    margin: 0 auto; /* Center the form on the page */
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  form input, form textarea, form select {
    width: 100%; /* Make sure input elements don't exceed the width of the form */
    max-width: 500px; /* Set a maximum width for inputs */
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding in the element's width */
  }
  
  form button {
    width: auto;
    padding: 12px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #2980b9;
  }
  
  .button {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .button:hover {
    background-color: #2980b9;
  }
  .main-content {
    padding: 20px;
  }
  
  .video-box {
    width: 100%;
    height: 400px;
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .chat-section {
    margin-top: 20px;
  }
  
  .chat-box {
    background-color: #f1f1f1;
    height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .chat-input {
    display: flex;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .chat-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }